import React, { useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { createClient } from '@supabase/supabase-js';
import './Form.scss';
import classNames from 'classnames';
import CheckMark from './components/CheckMark/CheckMark';

const supabaseUrl = 'https://pvcgdydicbbrkjstmirx.supabase.co';
const supabaseKey = process.env.REACT_APP_PRIVATE_KEY as string;
const supabase = createClient(supabaseUrl, supabaseKey);

const Form: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [submitted, setSubmitted] = useState(localStorage.getItem('submitted') === 'true');
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = useCallback(async (data: FieldValues) => {
    const transformedData = {
      name: data.name,
      present: data.present === '1',
      champagne: data.champagne,
      white_wine: data.white_wine,
      red_wine: data.red_wine,
      cognac: data.cognac,
      vodka: data.vodka,
      tea: data.nonalc
    };

    setLoading(true);
    const response = await supabase.from('dmidia').insert(transformedData);
    if (!response.error) {
      // localStorage.setItem('submitted', 'true');
      setSubmitted(true);
    }
    setLoading(false);
  }, []);

  if (submitted) {
    return <CheckMark />;
  }

  return (
    <div className="form_wrapper">
      <p className="form_confirm_text">Пожалуйста, подтверди свое присутствие</p>
      <form onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
        <fieldset className="form_content_wrapper" disabled={loading}>
          <input
            className={classNames('form_input', { ['form_input_error']: errors.name })}
            {...register('name', { required: true, minLength: 3 })}
            id="name"
            placeholder={'Представьтесь, семья ...'}
          />
          <p className="form_title">Присутствие</p>
          <fieldset className={classNames('form_fieldset', { ['form_fieldset_error']: errors.present })}>
            <div>
              <input {...register('present', { required: true })} type="radio" id="0" value={1} />
              <label className="label" htmlFor="0">
                Придем
              </label>
            </div>
            <div>
              <input {...register('present', { required: true })} type="radio" id="1" value={0} />
              <label className="label" htmlFor="1">
                Прийти не получится
              </label>
            </div>
          </fieldset>
          <p className="form_title">Предпочтения по напиткам</p>
          <div>
            <input {...register('champagne')} type="checkbox" id="champagne" />
            <label className="label" htmlFor="champagne">
              <span></span>
              Шампанское
            </label>
          </div>
          <div>
            <input {...register('white_wine')} type="checkbox" id="white_wine" />
            <label className="label" htmlFor="white_wine">
              <span></span>
              Белое вино
            </label>
          </div>
          <div>
            <input {...register('red_wine')} type="checkbox" id="red_wine" />
            <label className="label" htmlFor="red_wine">
              <span></span>
              Красное вино
            </label>
          </div>
          <div>
            <input {...register('cognac')} type="checkbox" id="cognac" />
            <label className="label" htmlFor="cognac">
              <span></span>
              Коньяк
            </label>
          </div>
          <div>
            <input {...register('vodka')} type="checkbox" id="vodka" />
            <label className="label" htmlFor="vodka">
              <span></span>
              Водка
            </label>
          </div>
          <div>
            <input {...register('nonalc')} type="checkbox" id="nonalc" />
            <label className="label" htmlFor="nonalc">
              <span></span>
              Сок, чай
            </label>
          </div>
          <button className="form_submit_button" type="submit" disabled={loading}>
            {loading ? (
              <div className="lds-heart">
                <div />
              </div>
            ) : (
              'Отправить'
            )}
          </button>
        </fieldset>
      </form>
    </div>
  );
};

export default Form;
