import React, { useRef } from 'react';

import photo from './assets/mainfst.jpg';
import royalHall from './assets/place.jpg';
import hands from './assets/hannds.jpg';
import heartPhoto from './assets/countd.jpg';
import check from './assets/check.svg';
import ruble from './assets/ruble.svg';
import grey1 from './assets/ovrsbei.jpg';
import grey2 from './assets/pugbej.jpg';
import white from './assets/blackper.jpg';
import './App.scss';
import Countdown from './components/Countdown/Countdown';
import Form from './components/Form/Form';
import Text from './components/ParallaxText/ParallaxText';
import AnimationWrapper from './components/AnimationWrapper/AnimationWrapper';

import { useInView } from 'framer-motion';

const App: React.FC = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '0px 100px -50px 0px' });
  const pathname = document.location.pathname;

  return (
      <div className="wrapper">
        <div ref={ref} className="first_wrapper">
          <div className="first_inner_border">
            <span className="first_logo">Dmitry&Daria</span>
            <Text/>
            <img
                style={{
                  transform: isInView ? 'none' : 'translateY(-25px)',
                  opacity: isInView ? 1 : 0,
                  transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s'
                }}
                className="first_photo"
                src={photo}
            />
            <div className="first_text_wrapper">
              <p className="first_text_name">{pathname === '/kostya' ? 'Дорогие Костя и Аня' : 'Дорогие Близкие и Друзья'}</p>
              <AnimationWrapper>
                <>
                  <p className="first_description">Летом состоится очень важное и радостное для нас событие — наша
                    свадьба</p>
                  <p className="first_description">
                    Этот день невозможно представить без самых близких для нас людей, мы бы очень хотели, чтобы вы
                    провели его вместе с нами
                  </p>
                </>
              </AnimationWrapper>
            </div>
          </div>
        </div>
        <div className="second_wrapper">
          <div className="second_when_image_wrapper" id="card_1">
            <img src={hands}/>
          </div>
          <div className="second_card" id="card_2">
            <p className="second_card_question">Когда?</p>
            <AnimationWrapper>
              <p className="second_card_text">25 июля 2025</p>
            </AnimationWrapper>
          </div>
          <div className="second_card" id="card_3">
            <p className="second_card_question">Где?</p>
            <AnimationWrapper>
              <>
                <p className="second_card_text" style={{fontSize: 22}}>Шахматный дворец</p>
                <p className="second_card_question" style={{padding: '0 5px'}}>г. Саратов, Соборная площадь, д.2</p>
              </>
            </AnimationWrapper>
          </div>
          <div className="second_when_image_wrapper">
            <img className="second_royal" src={royalHall} id="card_4"/>
          </div>
        </div>
        <div className="org_info" style={{ border: 'none', borderBottom: '1px solid black', marginBottom: '0' }}>
          <AnimationWrapper>
            <p>Несмотря на то, что мы очень любим детей, мы хотели бы в полной мере насладиться моментом праздника. Поэтому обращаем Ваше внимание, что мы ждем гостей возраста <strong style={{ color: '#91765b' }}>18+</strong></p>
          </AnimationWrapper>
        </div>
        <div className="countdown_block">
          <AnimationWrapper>
            <p className="countdown_text">До нашего важного события осталось</p>
          </AnimationWrapper>
          <Countdown/>
        </div>
        <div className="heart_photo_wrapper">
          <img src={heartPhoto} className="heart_photo"/>
        </div>
        <div className="third_wrapper">
          <div className="third_title">
            <AnimationWrapper>
              <p>ПРОГРАММА</p>
            </AnimationWrapper>
          </div>
          <div className="third_schedule">
            <div className="third_schedule_item">
              <AnimationWrapper>
                <span className="third_schedule_time">14:30</span>
              </AnimationWrapper>
              <span className="third_schedule_minus">—</span>
              <div className="third_schedule_text_wrapper">
                <AnimationWrapper>
                  <p className="third_schedule_text_title">Сбор гостей, фуршет</p>
                </AnimationWrapper>
                <AnimationWrapper>
                  <p className="third_schedule_text_desc">
                    По адресу: г. Саратов, Соборная площадь, д.2 (Шахматный дворец). Обязательно угощайтесь шампанским. Пузырьки отлично создают праздничное настроение.
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="third_schedule_item">
              <AnimationWrapper>
                <span className="third_schedule_time">15:00</span>
              </AnimationWrapper>
              <span className="third_schedule_minus">—</span>
              <div className="third_schedule_text_wrapper">
                <AnimationWrapper>
                  <p className="third_schedule_text_title">Торжественная церемония</p>
                </AnimationWrapper>
                <AnimationWrapper>
                  <p className="third_schedule_text_desc">
                    Скрывать улыбки, стесняться эмоций, грустить и тосковать – ЗАПРЕЩАЕТСЯ!
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="third_schedule_item">
              <AnimationWrapper>
                <span className="third_schedule_time">15:30</span>
              </AnimationWrapper>
              <span className="third_schedule_minus">—</span>
              <div className="third_schedule_text_wrapper">
                <AnimationWrapper>
                  <p className="third_schedule_text_title">Фотосессия</p>
                </AnimationWrapper>
                <AnimationWrapper>
                  <p className="third_schedule_text_desc">
                    Фотосессия гостей с молодожёнами
                    пройдет под девизом:
                    «Остановись, мгновение! Ты прекрасно!»
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="third_schedule_item">
              <AnimationWrapper>
                <span className="third_schedule_time">16:00</span>
              </AnimationWrapper>
              <span className="third_schedule_minus">—</span>
              <div className="third_schedule_text_wrapper">
                <AnimationWrapper>
                  <p className="third_schedule_text_title">Свадебный банкет</p>
                </AnimationWrapper>
                <AnimationWrapper>
                  <p className="third_schedule_text_desc">
                    Будем благодарны, если Вы воздержитесь от криков «Горько» на празднике, ведь поцелуй - знак выражения чувств, он не может быть по заказу.
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="third_schedule_item">
              <AnimationWrapper>
                <span className="third_schedule_time">23:00</span>
              </AnimationWrapper>
              <span className="third_schedule_minus">—</span>
              <div className="third_schedule_text_wrapper">
                <AnimationWrapper>
                  <p className="third_schedule_text_title">Окончание вечера</p>
                </AnimationWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className="org_info" style={{  marginBottom: '0', borderColor: 'black', borderWidth: '1px' }}>
          <AnimationWrapper>
            <p>В день свадьбы мы будем немного заняты, поэтому,
              в случае возникновения вопросов, Вы всегда
              можете обратиться за помощью к нашему координатору - <br/>Елизавета 8 (908) 555-10-38</p>
          </AnimationWrapper>
        </div>
        <div className="fourth_wrapper">
          <AnimationWrapper>
            <p className="fourth_title">О дресс-коде</p>
          </AnimationWrapper>
          <AnimationWrapper>
            <p className="fourth_text">
              Мы очень старались сделать торжество красивым и гармоничным, поэтому будем признательны, если Вы
              поддержите цветовую палитру нашей
              свадьбы.
            </p>
          </AnimationWrapper>
          <div className="fourth_block">
            <img className="imgLeft" src={grey2}/>
            <img className="imgRight" src={grey1}/>
            <AnimationWrapper>
              <span className="fourth_text">От светло- до темно-бежевого</span>
            </AnimationWrapper>
          </div>
          <div className="fourth_block_white">
            <AnimationWrapper>
              <span className="fourth_text_white">Черный можно!</span>
            </AnimationWrapper>
            <img className="imgWhite" src={white}/>
          </div>
          <div className="colors_wrapper">
            <div className="color_block" id="white"/>
            <div className="color_block" id="metal"/>
            <div className="color_block" id="shine"/>
          </div>
          <AnimationWrapper>
            <span className="color_desc">Мужчины: классический костюм (рубашка, брюки), (жилет и пиджак, по желанию)</span>
          </AnimationWrapper>
        </div>
        <div className="fifth_wrapper">
          <AnimationWrapper>
            <p className="card_title">Подарки</p>
          </AnimationWrapper>
          <AnimationWrapper>
            <p className="card_description">Свои тёплые слова и пожелания приносите в сердцах, а подарки - в конверте.
            </p>
          </AnimationWrapper>
          <div className='gifts_wrapper'>
            <AnimationWrapper>
              <div className='gift'>
                <img className='gift_mark' src={check} />
                <img className='gift_icon' src={ruble} />
              </div>
            </AnimationWrapper>
        </div>
        </div>
        <Form/>
      </div>
  );
};

export default App;
